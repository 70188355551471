import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/about/Hero";
import Content from "../sections/about/Content";
import Stats from "../sections/about/Stats";
import Problem from "../sections/about/Problem";
import CTA from "../sections/CTA/CTA";
import Wave from "../sections/common/Wave";
import WaveReverse from "../sections/common/WaveReverse";
import SEO from '../components/SEO';
import imgC from "../assets/image/inner-page/jpg/cooking.jpg";

const PricingPage = () => {

  // Example of dynamically using location prop as a crumbLabel
  // NOTE: this code will not work for every use case, and is only an example
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="How Digma began and where we are heading"
          description="A brief overview into the creation of Digma Interactive Video."
          image={imgC}
        />
        <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
            <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info">Info</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>              
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/about">About</Link>
              </li>
              </ol>
           </nav>
        </div>
        <Hero />
        <Content />
        <Wave color="#0c426d" />         
        <Stats />
        <WaveReverse pos="center" background="#0c426d" color="#F7F9FC" />         
        <Problem className="grey" />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
