import React from "react";

import imgb from "../../assets/image/hero/frustrated.jpeg";
import imgC from "../../assets/image/inner-page/jpg/cooking.jpg";
import imgDP from "../../assets/image/home-2/png/dot-pattern-black.png";

const Content = () => {
  return (
    <div className="pb-11 pb-md-15 pb-lg-27">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {/* Right */}
          <div className="order-1 col-xl-5 col-lg-5 col-md-5 col-xs-10">
            <div className="about-content-image-group-1 mb-8 mb-lg-0">
              <h3 className="">How it began</h3>
              <div className="img-1">
                <img
                  className="w-100 rounded-10"
                  src={imgb}
                  alt="Digma Mobile Interactive video"
                  data-aos="fade-right"
                  data-aos-duration={600}
                />
                <div className="img-2 z-index-n1 mt-n13">
                  <img
                    className="w-100"
                    src={imgDP}
                    alt="Spotted pattern"
                    data-aos="fade-right"
                    data-aos-duration={900}
                    data-aos-delay={300}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Right */}
          {/* Left */}
          <div className="order-2 col-xl-6 col-lg-7 col-md-7 col-xs-10">
            <div
              className="mx-lg-14 mx-xl-14"
              data-aos="fade-left"
              data-aos-duration={600}
            >
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
              Having the ability to instantly retrieve information at the touch of a button - or at the the tip of your fingers - has become part of everyday modern life. 
              </p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
              Yet amidst this progress one neglected area that has yet to be modified is today's mobile video technology. 
              </p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
              Our prerogative was to develop a new, exciting and practical way to retrieve important and timely information within video content. To drastically move on from the clunky and frustrating process of scrubbing backwards and forwards on a timeline. A process that seems so archaic in today’s accelerated technological climate.
              </p>
            </div>
          </div>
          {/* Left End */}
          {/* Left */}
          <div className="order-3 col-xl-6 col-lg-7 col-md-7 col-xs-10">
            <div
              className="mx-lg-14 mx-xl-14"
              data-aos="fade-left"
              data-aos-duration={600}
            >
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
              Committed to the concept of retrieving timely content on demand, our aim was to take video technology into the next era. 
              </p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
              The result is DIGMA, a newly developed video player suited for every type of industry. From cook-a-long videos and drone fly through tours, to marketing and real estate. 
              </p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
              Let us help you give life back to old videos and transform new content into an interactive experience that delivers beyond expectations.
              </p>
            </div>
          </div>
          {/* Left End */}            
          {/* Right */}
          <div className="order-4 col-xl-5 col-lg-5 col-md-5 col-xs-10">
            <div className="about-content-image-group-1 mb-8 mb-lg-0">
              <h3 className="">And then...</h3>
              <div className="img-1">
                <img
                  className="w-100 rounded-10"
                  src={imgC}
                  alt="Digma Mobile Interactive Cooking Video"
                  data-aos="fade-right"
                  data-aos-duration={600}
                />
                <div className="img-2 z-index-n1 mt-n13">
                  <img
                    className="w-100"
                    src={imgDP}
                    alt="Dotted pattern"
                    data-aos="fade-right"
                    data-aos-duration={900}
                    data-aos-delay={300}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Right */}
        
        </div>
      </div>
    </div>
  );
};

export default Content;
